<i18n>
ru:
  title: Ваш заказ
  totalPay: Итого к оплате
ua:
  title: Ваше замовлення
  totalPay: Загалом до сплати
us:
  title: Your order
  totalPay: Total
</i18n>

<template>
  <div
    class="v-d-flex v-flex-column v-second-step-details"
    v-if="clientStore.ClientState.data"
  >
    <div
      class="v-typography-title-3 v-mb-lg"
      v-html="translate('secondStepDetails.title')"
    />

    <cart-rhudaur-second-step-items />

    <div class="v-second-step-details__hr">
      <hr />
    </div>

    <cart-rhudaur-summary-page in-second-stage />

    <div class="v-second-step-details__hr">
      <hr />
    </div>
    <div
      v-if="appConfig.RestaurantSettingsPreRun.ServiceFeeEnabled"
      class="v-mb-lg"
    >
      <ui-checkbox
        :label="translate('serviceFee.serviceFee')"
        v-model:checked="serviceFeeActive"
        label-class-name="v-d-flex"
      >
        <span
          class="v-mr-xxs v-typography-text-4"
          v-html="translate('serviceFee.serviceFee')"
        />
        <common-currency
          :amount="appConfig.RestaurantSettingsPreRun.ServiceFeePrice"
        />
      </ui-checkbox>
    </div>
    <div class="v-d-flex v-justify-content-between">
      <div
        class="v-typography-title-5"
        v-html="translate('secondStepDetails.totalPay')"
      />
      <common-currency
        class="v-typography-title-5"
        :amount="clientStore.ClientState.data.FinalCalculatePrice"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()

const clientStore = useClientStore()

const { translate } = useI18nSanitized()

const serviceFeeActive = computed<boolean>({
  get: () => clientStore.ClientState?.data?.ServiceFee?.Active ?? false,
  set: (value: boolean) => {
    clientStore.addServiceFee(value)
  }
})
</script>

<style lang="scss">
.v-second-step-details {
  &__hr {
    padding: 0.855rem 0;
  }
}
</style>
