<i18n>
ru:
  callBack: Перезвонить для подтверждения заказа
  receiptReceivingMethod: Способ получения чека
ua:
  callBack: Передзвонити для підтвердження замовлення
  receiptReceivingMethod: Спосіб отримання чека
us:
  callBack: Call back to confirm the order
  receiptReceivingMethod: Receipt receiving method
</i18n>

<template>
  <div
    class="v-d-flex v-flex-column v-second-step-other"
    v-if="clientStore.ClientState.data"
  >
    <div
      class="v-d-flex v-flex-column"
      v-if="appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0"
    >
      <span
        class="v-typography-title-5 v-mb-md"
        v-html="translate('secondStepOther.receiptReceivingMethod')"
      />
      <ui-radio
        hide-label
        required
        v-form-validator="{
          Form: validatorForm,
          Value: receiptSendType ?? null,
          Required: true,
          Validations: []
        }"
        :label="translate('receiptSend.receiptMethod')"
        :options="receiptSendTypesSorted.map((item) => item.Type)"
        v-model:selected="receiptSendType"
      >
        <template #option="option: { value: number }">
          <div
            v-if="option.value === ReceiptSendType.Email"
            v-html="translate('receiptSend.email')"
          />
          <div
            v-else-if="option.value === ReceiptSendType.Sms"
            v-html="translate('receiptSend.sms')"
          />
          <div
            v-else-if="option.value === ReceiptSendType.Print"
            v-html="translate('receiptSend.printed')"
          />
        </template>
      </ui-radio>
    </div>
    <div
      class="v-second-step-other__hr"
      v-if="
        appConfig.RestaurantSettingsPreRun.AllowDisableCallback &&
        appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0
      "
    >
      <hr />
    </div>
    <ui-switch
      :disabled="appConfig.RestaurantSettingsPreRun.AllowDisableCallback"
      :label="translate('secondStepOther.callBack')"
      v-model:checked="userOrderedCallback"
      label-class-name="v-typography-title-5"
    />
  </div>
</template>

<script setup lang="ts">
import type { HasValidatorForm } from '~types/props'

import { vFormValidator } from '@arora/common'

import { ReceiptSendType } from '~api/consts'

defineProps<HasValidatorForm>()

const appConfig = useAppConfig()

const clientStore = useClientStore()

const { translate } = useI18nSanitized()

const receiptSendType = computed<ReceiptSendType>({
  get: () => clientStore.ClientState?.data?.StateOrderData?.ReceiptSendType ?? 0,
  set: (value) => {
    clientStore.updateOrderData({
      receiptRecipient: emailOrPhoneFromPersonalInfo(value),
      receiptSendType: value ?? 0
    })
  }
})

function emailOrPhoneFromPersonalInfo(value: ReceiptSendType): string {
  switch (value) {
    case ReceiptSendType.Email: {
      return clientStore.ClientState?.data?.StateOrderData?.Email ?? ''
    }
    case ReceiptSendType.Sms: {
      return clientStore.ClientState?.data?.StateOrderData?.Phone ?? ''
    }
    default: {
      return ''
    }
  }
}

const userOrderedCallback = computed<boolean>({
  get: () => clientStore.ClientState.data?.UserOrderedCallback ?? false,
  set: (value) => {
    clientStore.setCallbackState(value)
  }
})

const receiptSendTypesSorted = appConfig.VueSettingsPreRun.ReceiptSendTypes.sort(
  (a, b) => a.SortWeight - b.SortWeight
)

onMounted(() => {
  if (appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0) {
    receiptSendType.value = receiptSendTypesSorted[0].Type
  }
})
</script>

<style lang="scss">
.v-second-step-other {
  &__hr {
    padding: 0.855rem 0;
  }
}
</style>
