<i18n>
ru:
  back: Назад
  checkout: 'Оформить за {amount}'
  orderDelivery: Доставка заказа
  orderPickup: Самовывоз заказа
ua:
  back: Назад
  checkout: 'Оформити за {amount}'
  orderDelivery: Доставка замовлення
  orderPickup: Самовивіз замовлення
us:
  back: Back
  checkout: 'Checkout for {amount}'
  orderDelivery: Order delivery
  orderPickup: Order pickup
</i18n>

<template>
  <div class="v-container v-cart-second-step">
    <div class="v-row">
      <div class="v-col-12 v-cart-second-step__button">
        <ui-button
          :size="32"
          color="grey"
          :label="translate('secondStepPage.back')"
          :click-event="goBack"
        >
          <ui-icon
            color="mono1000"
            :size="24"
            name="chevronLeft"
          />
          <span
            class="v-color-mono-1000"
            v-html="translate('secondStepPage.back')"
          />
        </ui-button>
      </div>
      <div
        class="v-col-12 v-cart-second-step__title v-typography-title-1"
        v-if="clientStore.courierDelivery"
        v-html="translate('secondStepPage.orderDelivery')"
      />
      <div
        class="v-col-12 v-cart-second-step__title v-typography-title-1"
        v-else-if="clientStore.selfService"
        v-html="translate('secondStepPage.orderPickup')"
      />
      <div class="v-col-12 v-col-md-8">
        <div class="v-cart-second-step__card">
          <cart-rhudaur-second-step-personal-info :validator-form="validatorForm" />
        </div>
        <div class="v-cart-second-step__card">
          <cart-rhudaur-second-step-address-select />
          <cart-comment :validator-form="validatorForm" />
        </div>
        <div class="v-cart-second-step__card">
          <div class="v-mb-xs">
            <cart-delivery-time-page />
          </div>
          <cart-rhudaur-second-step-priority-service />
        </div>
        <div class="v-cart-second-step__card">
          <cart-rhudaur-second-step-pay-method />
        </div>
        <div
          class="v-cart-second-step__card"
          v-if="
            appConfig.RestaurantSettingsPreRun.AllowDisableCallback ||
            appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0
          "
        >
          <cart-rhudaur-second-step-other :validator-form="validatorForm" />
        </div>
      </div>
      <div class="v-col-12 v-col-md-4">
        <div class="v-cart-second-step__card">
          <cart-rhudaur-second-step-details />
        </div>
        <div class="v-cart-second-step__button">
          <cart-create-order-button />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const clientStore = useClientStore()

const { translate } = useI18nSanitized()
const { eventEmit } = useEmitter()

const validatorForm = 'cart'

async function goBack(): Promise<void> {
  await navigateTo(appConfig.VueSettingsPreRun.Links.MainLink)

  eventEmit('v-returned-back-from-cart')
}
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-cart-second-step {
  @include mixins.sm {
    padding-right: 0;
    padding-left: 0;
  }

  &__button,
  &__title {
    margin-bottom: 2.145rem;

    @include mixins.sm {
      margin: 0 1.145rem 2.145rem;
    }
  }

  &__card {
    padding: 1.715rem;
    margin-bottom: 1.715rem;
    background: variables.$White;
    border-radius: variables.$BorderRadius;
    box-shadow: 0 4px 16px 0 rgba(54, 54, 54, 0.06);
  }
}
</style>
