<i18n>
ru:
  priorityCooking: Приоритетное приготовление
  priorityDelivery: Приоритетная доставка
ua:
  priorityCooking: Приоритетне приготування
  priorityDelivery: Приоритетна доставка
us:
  priorityCooking: Priority cooking
  priorityDelivery: Priority delivery
</i18n>

<template>
  <transition
    mode="out-in"
    name="slidedown"
  >
    <div
      v-if="
        (appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryEnabled &&
          clientStore.courierDelivery) ||
        (appConfig.RestaurantSettingsPreRun.PriorityServicePickupEnabled &&
          clientStore.selfService)
      "
      class="v-d-flex"
    >
      <ui-checkbox
        class="v-w-100"
        :label="translate('serviceFee.serviceFee')"
        v-model:checked="priorityService"
        label-class-name="v-d-flex v-w-100 v-align-items-center"
      >
        <span
          class="v-mr-xxs"
          v-html="
            clientStore.courierDelivery
              ? translate('priorityService.priorityDelivery')
              : translate('priorityService.priorityCooking')
          "
        />
        <common-currency
          v-if="clientStore.courierDelivery"
          class="v-mr-xxs v-font-weight-600"
          :amount="appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryPrice"
        />
        <common-currency
          v-else
          class="v-mr-xxs v-font-weight-600"
          :amount="appConfig.RestaurantSettingsPreRun.PriorityServicePickupPrice"
        />
        <common-popover class="v-pointer">
          <template #hoverable>
            <span>
              <ui-icon
                color="mono400"
                :size="24"
                name="Inform"
              />
            </span>
          </template>
          <template #content>
            <div
              v-html="
                clientStore.courierDelivery
                  ? translate('priorityService.priorityDescriptionIntroDelivery')
                  : translate('priorityService.priorityDescriptionIntroSelfService')
              "
            />
            <div
              v-html="
                clientStore.courierDelivery
                  ? translate('priorityService.priorityDescriptionDelivery')
                  : translate('priorityService.priorityDescriptionSelfService')
              "
            />
          </template>
        </common-popover>
      </ui-checkbox>
    </div>
  </transition>
</template>

<script setup lang="ts">
const clientStore = useClientStore()

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
const priorityService = computed<boolean>({
  get: () => clientStore.ClientState?.data?.PriorityService?.Active ?? false,
  set: (value: boolean) => {
    clientStore.priorityService(value)
  }
})
</script>
