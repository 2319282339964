<i18n>
ru:
  addAddress: Добавить адрес
  deliverHere: 'Доставим сюда:'
  edit: Изменить
  delete: Удалить
  chooseAddress: Выбрать адрес
  chooseDelivery: Выбрать доставку
  choosePickup: Выбрать самовывоз
  title: Адрес ресторана
ua:
  addAddress: Додати адресу
  deliverHere: 'Доставимо сюди:'
  edit: Змінити
  delete: Видалити
  chooseAddress: Обрати адресу
  chooseDelivery: Обрати доставку
  choosePickup: Обрати самовивіз
  title: Адреса ресторану
us:
  addAddress: Add address
  edit: Edit
  delete: Delete
  deliverHere: 'We’ll deliver here:'
  chooseAddress: Choose address
  chooseDelivery: Choose delivery
  choosePickup: Choose pickup
  title: Restaurant address
</i18n>

<template>
  <div class="v-second-step-address-select v-mb-xxl">
    <div class="v-d-flex v-justify-content-between">
      <div
        class="v-typography-title-3"
        v-html="translate('secondStepAddressSelect.title')"
      />
      <ui-button
        color="secondary"
        :size="14"
        type="text-with-button"
        :label="
          translate(
            isSelfService
              ? 'secondStepAddressSelect.chooseDelivery'
              : 'secondStepAddressSelect.choosePickup'
          )
        "
        :click-event="selectOrderType"
      />
    </div>

    <arora-carousel
      max-items-auto
      :items="availableButtons"
      pagination-type="none"
      :space-between="16"
      snap-align="start"
    >
      <template #item="button: addressButton">
        <div
          v-if="button.IsCallForPopupButton"
          class="v-address-button v-address-button--popup v-pointer v-d-flex v-flex-column v-justify-content-between v-align-items-center"
          @click="changeAddress"
        >
          <ui-icon
            color="mono1000"
            disable-hover
            name="add"
            :size="32"
          />
          <div
            v-html="
              translate(
                isSelfService
                  ? 'secondStepAddressSelect.chooseAddress'
                  : 'secondStepAddressSelect.addAddress'
              )
            "
          />
        </div>
        <div
          v-else-if="button.SavedAddress"
          class="v-address-button v-address-button--address v-pointer v-d-flex v-flex-column v-justify-content-between v-align-items-center"
          @click="() => setAddressInState(button.SavedAddress)"
        >
          <div
            class="v-d-flex v-w-100 v-justify-content-between v-align-items-center"
          >
            <span
              class="v-typography-title-7"
              v-html="translate('secondStepAddressSelect.deliverHere')"
            />
            <ui-dropdown-menu
              :items="[
                {
                  title: translate('secondStepAddressSelect.edit'),
                  onClick: () => editAddress(button.SavedAddress!)
                },
                {
                  title: translate('secondStepAddressSelect.delete'),
                  onClick: () => showDeleteAddressConfirm(button.SavedAddress!)
                }
              ]"
            />
          </div>
          <span
            class="v-typography-text-4"
            v-html="savedAddressString(button.SavedAddress, true)"
          />
        </div>
      </template>
    </arora-carousel>
  </div>
</template>

<script setup lang="ts">
import type { MapsSavedAddress, TwoStepsSavedAddress } from '~types/accountStore'

import type { GUID, HasID } from '@arora/common'

import UiDropdownMenu from '~/ui/uiDropdownMenu.vue'

import { Guid, OrderType } from '~api/consts'

const appConfig = useAppConfig()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { setAddressInState } = useAddressSelector()
const { translate } = useI18nSanitized()
const { savedAddressString } = useAddressSelector()

const isSelfService = computed<boolean>(
  () => clientStore.ClientState.data?.OrderType === OrderType.NoShipment
)

type addressButton = HasID & {
  IsCallForPopupButton: boolean
  SavedAddress: MapsSavedAddress | TwoStepsSavedAddress | null
}

const availableButtons = computed<addressButton[]>(() => {
  const result: addressButton[] = [
    {
      ID: Guid.Empty,
      IsCallForPopupButton: true,
      SavedAddress: null
    }
  ]

  if (isSelfService.value) {
    /* empty */
  } else if (appConfig.RestaurantSettingsPreRun.OneStageCart) {
    result.push(
      ...(accountStore.MapsSavedAddresses.data ?? []).map((savedAddress) => {
        return {
          ID: savedAddress.ID,
          IsCallForPopupButton: false,
          SavedAddress: savedAddress
        }
      })
    )
  } else {
    result.push(
      ...(accountStore.TwoStepsSavedAddresses.data ?? []).map((savedAddress) => {
        return {
          ID: savedAddress.Id,
          IsCallForPopupButton: false,
          SavedAddress: savedAddress
        }
      })
    )
  }

  return result
})

async function selectOrderType(): Promise<void> {
  clientStore.selectOrderType({
    orderType: isSelfService.value ? OrderType.CourierDelivery : OrderType.NoShipment
  })

  await changeAddress()
}

onMounted(() => {
  accountStore.initSavedAddresses(appConfig.RestaurantSettingsPreRun.OneStageCart)
})

async function changeAddress(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'addressSelectorPopup',
    popupProperties: new Map<string, unknown>([
      ['fromCart', true],
      ['onEntrance', false],
      ['onProductAdd', false]
    ])
  })
}

async function editAddress(
  address: MapsSavedAddress | TwoStepsSavedAddress
): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: appConfig.RestaurantSettingsPreRun.OneStageCart
      ? 'mapsSavedAddressesPopup'
      : 'savedAddressesPopup',
    popupProperties: new Map<string, unknown>([['address', address]])
  })
}

async function showDeleteAddressConfirm(
  address: MapsSavedAddress | TwoStepsSavedAddress
): Promise<void> {
  await popupStore.showConfirm({
    message: translate('savedAddressesPage.confirmDelete', {
      address: savedAddressString(address, true)
    }),
    type: 'warning',
    yesFunction: () => {
      deleteAddress('ID' in address ? address.ID : address.Id)
    },
    yesOrNo: true,
    yesText: translate('savedAddressesPage.delete')
  })
}

async function deleteAddress(id: GUID): Promise<void> {
  try {
    await accountStore.deleteSavedAddress(
      id,
      appConfig.RestaurantSettingsPreRun.OneStageCart
    )

    await accountStore.loadSavedAddresses(
      appConfig.RestaurantSettingsPreRun.OneStageCart
    )
  } catch (error) {
    await (error?.message
      ? popupStore.showError(error.message)
      : popupStore.showException(error))
  }
}
</script>

<style lang="scss">
@use 'assets/variables';

.v-second-step-address-select {
  .v-address-button {
    background: variables.$White;
    border-radius: variables.$BorderRadius;

    &--popup {
      padding: 1.075rem 0.85rem;
      border: 1px solid variables.$Mono400;
      gap: 0.355rem;
      height: 100%;
    }

    &--address {
      padding: 0.5rem 0.85rem 1.075rem;
      border: 1px solid variables.$Mono100;
      gap: 0.355rem;
      height: 100%;
      max-width: 240px;
      min-height: 100px;
    }
  }
}
</style>
